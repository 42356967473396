* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

.content {
  justify-content: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQYV2NkQAOMUH4DAwMDCDPABODqMAQAKe4BBZTlhh0AAAAASUVORK5CYII=);
  background-size: auto, cover;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
